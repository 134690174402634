import React from 'react';
import '../App.css';

function Privacy() {
  return (
    <div className="PrivacyPolicy">
      <header className="PrivacyPolicy-header">
        <h1 className="bold">Política de Privacidade</h1>
        <h2 className="bold">1. Introdução</h2>
        <p>Bem-vindo ao Arthos. Nós valorizamos sua privacidade e estamos comprometidos em proteger suas informações pessoais. Esta política de privacidade descreve como coletamos, usamos, divulgamos e protegemos suas informações quando você usa nosso aplicativo disponível na Apple Store.</p>
        
        <h2 className="bold">2. Informações que Coletamos</h2>
        <h3 className="bold">2.1 Informações Pessoais</h3>
        <p>Podemos coletar informações que podem identificá-lo pessoalmente, como seu nome, endereço de e-mail, número de telefone e outras informações semelhantes quando você cria uma conta, faz login ou interage conosco.</p>

        <h2 className="bold">3. Uso das Informações</h2>
        <p>Usamos as informações que coletamos para:</p>
        <ul>
          <li>Fornecer e melhorar nosso aplicativo e serviços.</li>
          <li>Personalizar sua experiência no aplicativo.</li>
          <li>Enviar notificações importantes e atualizações.</li>
          <li>Responder a suas solicitações de suporte ao cliente.</li>
          <li>Analisar o uso do aplicativo para melhorar nosso serviço e desenvolver novos recursos.</li>
          <li>Cumprir obrigações legais e proteger nossos direitos.</li>
        </ul>

        <h2 className="bold">4. Compartilhamento de Informações</h2>
        <p>Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes circunstâncias:</p>
        <ul>
          <li>Com seu consentimento explícito.</li>
          <li>Para cumprir leis ou responder a processos legais.</li>
          <li>Para proteger nossos direitos, propriedade e segurança, bem como os direitos, propriedade e segurança de nossos usuários e do público.</li>
          <li>Com prestadores de serviços que nos ajudam a operar nosso aplicativo e fornecer nossos serviços, desde que estejam sujeitos a obrigações de confidencialidade.</li>
        </ul>

        <h2 className="bold">5. Segurança das Informações</h2>
        <p>Implementamos medidas de segurança técnicas e organizacionais para proteger suas informações pessoais contra acesso não autorizado, perda, destruição ou alteração. No entanto, nenhum método de transmissão pela Internet ou de armazenamento eletrônico é totalmente seguro, e não podemos garantir segurança absoluta.</p>

        <h2 className="bold">6. Retenção de Dados</h2>
        <p>Reteremos suas informações pessoais apenas pelo tempo necessário para cumprir as finalidades descritas nesta política, a menos que um período de retenção mais longo seja exigido ou permitido por lei.</p>

        <h2 className="bold">7. Direitos do Usuário</h2>
        <p>Você tem o direito de acessar, corrigir, atualizar ou solicitar a exclusão de suas informações pessoais. Para exercer esses direitos, entre em contato conosco usando as informações fornecidas na seção “Contato” abaixo.</p>

        <h2 className="bold">8. Alterações a Esta Política de Privacidade</h2>
        <p>Podemos atualizar esta política de privacidade periodicamente. Notificaremos você sobre quaisquer alterações publicando a nova política de privacidade em nosso aplicativo e atualizando a data de “Última Atualização” no topo desta política. Recomendamos que você revise esta política regularmente para se manter informado sobre nossas práticas de privacidade.</p>

        <h2 className="bold">9. Contato</h2>
        <p>Se você tiver dúvidas ou preocupações sobre esta política de privacidade ou nossas práticas de privacidade, entre em contato conosco: <a href="mailto:contato@rmtecho.com.br">contato@rmtecho.com.br</a></p>

        <h4 className="bold">Última Atualização: 15/07/2024</h4>
     
      </header>
    </div>
  );
}

export default Privacy;

